import React, { useEffect, useState } from "react";

import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import TermsOfSubscription from "./LegalPageComponents/LegalTerms";
import PrivacyPolicy from "./LegalPageComponents/PrivacyPolicy";
import DpaTerms from "./LegalPageComponents/DpaTerms";
import InformationSecurityPolicy from "./LegalPageComponents/InformationSecurityPolicy";

import Header from "./Header";

const LegalPage = () => {
  const [selectedPage, setSelectedPage] = useState("Terms of Subscription");
  const [numPages, setNumPages] = useState(null);

  //   const [userLocation, setUserLocation] = useState(null);

  //   useEffect(() => {
  //     // Fetch user's location based on IP address
  //     fetch("https://ipapi.co/json/")
  //       .then(async (response) => response.json())
  //       .then((data) => {
  //         setUserLocation(data.country);
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching location data:", error);
  //       });
  //   }, []);

  const handlePageChange = (page) => {
    setSelectedPage(page);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  // Array of pages with their respective content
  const pages = [
    {
      id: "Terms of Subscription",
      title: "Terms of Subscription",

      child: <TermsOfSubscription />,
    },
    {
      id: "Privacy Policy",
      title: "Privacy Policy",

      child: <PrivacyPolicy />,
    },
    {
      id: "DPA",
      title: "DPA",

      child: <DpaTerms />,
    },
    {
      id: "Information Security Policy",
      title: "Information Security Policy",

      child: <InformationSecurityPolicy />,
    },
  ];

  const currentPage = pages.find((page) => page.id === selectedPage);

  return (
    <div>
      <Header />

      <Box
        sx={{
          overflowY: "auto", // Enable vertical scrolling
          ml: "40px",
        }}
      >
        <Box display="flex" flexDirection="row">
          {/* Sidebar */}
          <Box
            sx={{
              mt: 8, // Adjust top margin as needed
              p: 2,
              width: 450,
              backgroundColor: "#f9f9f9",
              zIndex: 1, // Ensure it stays above other content
            }}
          >
            <List>
              {pages.map((page) => (
                <ListItem key={page.id} disablePadding sx={{ mb: 1 }}>
                  <ListItemButton
                    onClick={() => handlePageChange(page.id)}
                    selected={selectedPage === page.id}
                    sx={{
                      borderRadius: 1,
                      py: 1.5,
                      "&.Mui-selected": {
                        "&:hover": {},
                      },
                      "&:hover": {
                        backgroundColor: "action.hover",
                      },
                      transition: "background-color 0.2s ease-in-out",
                    }}
                  >
                    <ListItemText
                      primary={page.title}
                      primaryTypographyProps={{
                        variant: "body1",
                        fontWeight:
                          selectedPage === page.id ? "bold" : "normal",
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>

          {/* Main Content */}
          <Box
            sx={{
              flexGrow: 1,
              pt: 8, // Adjust top padding as needed
              display: "flex",
              flexDirection: "column",
            }}
          >
            {currentPage && currentPage.child}
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default LegalPage;
