import React, { useState } from "react";
import {
  Container,
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
  Paper,
  useTheme,
  styled,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const PrivacyPolicy = () => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const sections = [
    {
      title: "TERMS USED IN THIS PRIVACY POLICY",
      content: [
        "In this Privacy Policy:",
        "“Cookies” mean small files that are placed on your computer, mobile device or any other device by a website, containing, among other information, the details of your browsing history on that website.",
        "“Personal Information” means any information relating to an identified or identifiable natural person.",
        "“Privacy Policy” means this privacy policy.",
        "“Platform” means our website, applications, mobile applications and our products and services (including any of its related scripts, source code, instruction sets, and documentation)",
        "“we”, “us”, “our”, and “Augrade” refer to Augrade Inc and our affiliated companies worldwide.",
        "“you” refers to you as a visitor to the Platform;",
        "whether as a user, subscriber, or beneficiary of the Platform and its services;",
        "or as a person whose information is made available to us.",
      ],
    },
    {
      title: "ABOUT THIS PRIVACY POLICY",
      content: [
        "We appreciate the importance of data and its privacy.",
        "This Privacy Policy explains the types of information we collect or receive, how we handle your information, and how we comply with the requirements of applicable privacy laws.",
        "This Privacy Policy is applicable to any person who visits the Platform, or inquires about our Services, or avails of our Services, or whose information is otherwise made available to us in the context of the Services or otherwise.",
        "You may note that information and privacy practices of our business partners, service providers or software providers may be materially different from this Privacy Policy.",
        "We recommend that you review the privacy statements and policies of any such third parties with whom you interact.",
        "We do not make any representations concerning the privacy practices or policies of such third parties or terms of use of such websites, nor do we guarantee the accuracy, integrity, or quality of the information and content available on such websites.",
        "Their inclusion or exclusion on the Platform does not imply our endorsement.",
        "This Privacy Policy must be read alongside Terms of Use, and such other policy/ document as available on the Platform, the terms thereof are deemed to be included in this Privacy Policy by reference.",
        "This Privacy Policy is effectively part of the terms of Platform and is fundamental to your use of the Platform.",
      ],
    },
    {
      title: "INFORMATION COVERED UNDER PRIVACY POLICY",
      content: [
        "This Privacy Policy outlines the information we collect from you through our Platform.",
        "All Personal Information available on our Platform will be encrypted for security.",
        "Certain features of our site may be accessed without disclosing any Personal Information.",
        "however, for specific features or services associated with our Platform, Personal Information is required.",
        "To access certain features and benefits on our Platform, you may need to submit, or we may collect Personal Information which may include but is not limited to, your name and email address.",
        "Additionally, we may collect information from your social media accounts when you interact through our Platform.",
        "You are responsible for ensuring the accuracy of the Personal Information you provide to the Platform.",
        "Inaccurate or outdated information may affect your ability to use the Platform, the relevance of the information you receive, and our ability to communicate with you.",
        "For instance, it is important to maintain an up-to-date email address, as it is one of the primary methods, we use to communicate with you.",
        "By submitting any Personal Information to us, you acknowledge and consent to our collection, use, and disclosure of such information in accordance with this Privacy Policy and as permitted or required by law.",
        "If you choose to withdraw your consent or not provide the required Personal Information, we may be unable to offer certain services available on our Platform.",
        "Additionally, please be aware that we may process your data based on legitimate interests or for the fulfilment of a contract.",
      ],
    },
    {
      title: "DATA WE COLLECT OR PROCESS, AND ITS PURPOSE",
      content: [
        "We may process data about your use of the Platform (“ usage data ”).",
        "The usage data may include, but is not limited to your IP address, geographical location, browser type and version, operating system, referral source, length of your visit, page views and website navigation paths, information about the timing, frequency and pattern of your service use, along with information about your use of the Platform.",
        "The source of the usage data is public domain or information derived from your use of our Platform such as your buying behaviour.",
        "In addition, we may use third-party analytics tracking services such as Google Analytics and Facebook Pixel.",
        "This usage data may be processed for the purposes of analysing the use of the Platform in order to improve our performance metrics, and for communicating with you.",
        "The legal basis for this processing is your consent.",
        "In some cases, the legal basis for this processing is our legitimate interests, namely monitoring and improving the Platform and our products and services.",
        "We may process your information included in your personal profile on the Platform (“ profile data ”).",
        "The profile data may include your name, address, telephone number, email address, profile pictures, and registration details.",
        "The profile data may be processed for the purposes of enabling and monitoring your use of the Platform and for communicating with you.",
        "The legal basis for this processing is your consent.",
        "In some cases, the legal basis for this processing is our legitimate interests, namely the proper administration of the Platform and our products and services.",
        "We may process your personal data that are provided in the course of the use of our Platform (“ service data ”).",
        "The service data may include your name, address, telephone number, and email address.",
        "The source of the service data is you or your employer.",
        "The service data may be processed for the purposes of providing the Platform, maintaining back-ups of our databases, and for communicating with you.",
        "The legal basis for this processing is your consent.",
        "In some cases, the legal basis for this processing is our legitimate interests, namely the proper administration of the Platform and business.",
        "In some cases, the legal basis for this processing is the performance of a contract between you and us and/or taking steps, at your request, to enter into such a contract.",
        "We may process information that you post for publication on the Platform (“ publication data ”).",
        "The publication data may be processed for the purposes of enabling such publication and administering the Platform and our products and services.",
        "The legal basis for this processing is your consent.",
        "In some cases, the legal basis for this processing is our legitimate interests, namely the proper administration of the Platform and business.",
        "In some cases, the legal basis for this processing is the performance of a contract between you and us and/or taking steps, at your request, to enter into such a contract.",
        "We may process information contained in any enquiry you submit to us regarding the Platform (“ enquiry data ”).",
        "The enquiry data may be processed for the purposes of offering, marketing and selling relevant services to you and for communicating with you.",
        "The legal basis for this processing is your consent.",
        "We may process information relating to our customer relationships, including customer contact information (“ customer relationship data ”).",
        "The customer relationship data may include your name, your employer, your job title or role, your contact details, and information contained in communications between us and you or your employer.",
        "The source of the customer relationship data is you or your employer.",
        "The customer relationship data may be processed for the purposes of managing our relationships with customers, communicating with customers, keeping records of those communications, promoting our products and services to customers, and for communicating with you.",
        "The legal basis for this processing is your consent.",
        "In some cases, the legal basis for this processing is our legitimate interests, namely the proper administration of the Platform.",
        "In some cases, the legal basis for this processing is the performance of a contract between you and us and/or taking steps, at your request, to enter into such a contract.",
        "We may process information relating to transactions, including purchases of our products and services, that you enter into with us and/or through the Platform (“ transaction data ”).",
        "The transaction data may include your contact details, your banking details, your credit card details and your transaction details.",
        "The transaction data may be processed for the purpose of supplying the purchased goods and services, keeping proper records of those transactions, and for communicating with you.",
        "The legal basis for this processing is your consent.",
        "In some cases, the legal basis for this processing is our legitimate interests, namely the proper administration of our business.",
        "In some cases, the legal basis for this processing is the performance of a contract between you and us and/or taking steps, at your request, to enter into such a contract.",
        "We may process information that you provide to us for the purpose of subscribing to our email notifications and/or newsletters (“ notification data ”).",
        "The notification data may be processed for the purposes of sending you the relevant notifications and/or newsletters, and for communicating with you.",
        "The legal basis for this processing is your consent.",
        "We may process information contained in or relating to any communication that you send to us (“ correspondence data ”).",
        "The correspondence data may include the communication content and metadata associated with the communication.",
        "The correspondence data may be processed for the purposes of communicating with you and record-keeping.",
        "The legal basis for this processing is your consent.",
        "In some cases, the legal basis for this processing is our legitimate interests, namely the proper administration of the Platform and business and communications with our business counterparts.",
        "In addition to the specific purposes for which we may process your personal data set out in this Section, we may process any of your personal data identified in this Policy where necessary for:",
        "the establishment, exercise or defence of legal claims, whether in court proceedings or in an administrative or out-of-court procedure.",
        "The legal basis for this processing is our legitimate interests, namely the protection and assertion of our legal rights, your legal rights and the legal rights of others.",
        "the purposes of obtaining or maintaining insurance coverage, managing risks, or obtaining professional advice.",
        "The legal basis for this processing is our legitimate interests, namely the proper protection of our business against risk compliance with a legal obligation to which we are subject, or in order to protect your vital interests or the vital interests of another person."
      ],
    },
    {
      title: "USE YOUR INFORMATION",
      content: [
        "We will only use your personal data in a fair and reasonable manner, and where we have a lawful reason to do so.",
        "We primarily use the information available on the Platform for the following purposes:",
        "To provide access to our products and services and for use of the Platform.",
        "To manage your access to the Platform, and to send you content via push notifications, newsletters, subscriptions, etc.",
        "To send you service notifications related to your subscription such as renewal notifications, password resets and order confirmations.",
        "To manage customer service queries and complaints.",
        "To manage your privacy preferences and to ensure you only receive communications that you have requested, which may include using your details to suppress you from communications.",
        "To detect and reduce fraud and harassment.",
        "To prevent users from posting illegal, offensive or objectionable comments on the Platform.",
        "To share your information to third parties who would like to contact you with information regarding their own products and services.",
        "Those parties are responsible for their use of your data and you should read their privacy policies carefully.",
        "Other than where we have sought your consent, in certain cases, we may use your information where necessary to further our legitimate interests, where those legitimate interests are not overridden by your rights or interests, including:",
        "To measure customer and user response and engagement with our products and services and Platform such as online content, email newsletters and subscription offers.",
        "This may include sharing your information with third-parties who help us to analyse and measure these parameters.",
        "To ensure our products and the Platform are compatible with the browsers and operating systems used by you.",
        "To help us improve our customer and user experience and to support new product or feature development.",
        "To enable us to solicit feedback through customer satisfaction surveys and market research questionnaires (for which we may share your information with third-party service providers engaged by us).",
        "To create audience profiles for personalised advertising, marketing or research and development on and off the Platform.",
        "To detect and reduce fraudulent activity and for other security-related purposes such as to help us protect against harassment, crime or other security issues.",
        "For any purpose required by contract, law or regulation and to verify information that we provide to third-parties for compliance and audit purposes.",
        "To the extent required for identity verification, government sanctions screening and due diligence checks.",
        "Establishing, exercising, or defending legal rights in connection with legal proceedings (including any prospective legal proceedings) and seeking professional or legal advice in relation to such proceedings.",
        "We will ask you if you wish to opt out of marketing when you first sign up to receive our products or services.",
        "You can opt out of email marketing by clicking the ‘unsubscribe’ link at the bottom of our emails.",
        "Do note that this opt-out mechanism does not apply to important service notifications such as license expiry notifications, payment confirmations or where we have some other legal basis for contacting you.",
        "In order to deliver advertising and marketing messages that are relevant to you, we may use the information we have about you, to ensure that the advertising you see is of interest to you.",
        "You have the right to object to any of the above uses of your information, please contact us if you wish to do so.",
        "We will consider all objections reasonably, but there may be legal reasons where we deem that the use of your information is still appropriate.",
        "We are committed to explaining our decision to you in a timely manner.",
        "We do not store any account-related information or any payment instrument (including credit card) details.",
        "We are not liable for any loss or damage caused to you as a result of any disclosure (inadvertent or otherwise) of any information concerning your accounts (including third-party account) or payment instruments in the course of any transactions or payments made for any products and/or services by you through the Platform.",
      ],
    },
    {
      title: "EXTERNAL LINKS AND COOKIES",
      content: [
        "External links .",
        "This privacy policy only applies to our use of your data.",
        "Our services, products or the Platform may, from time to time, contain links to external websites.",
        "We are not responsible for the privacy policies or the content of such external websites and we recommend that you read the privacy policies on any external websites that you use.",
        "Similarly, if you are directed to the Platform from a third party, then we are not responsible for the privacy policy or practices of the third party.",
        "We strongly recommend you read their privacy policy.",
        "Cookies.",
        "Some of our web pages utilize 'cookies' and other tracking technologies.",
        "A 'cookie' is a small text file that may be used, for example, to collect information about website activity.",
        "Cookies may be created in your web browser when you visit any part of the Platform.",
        "Some cookies and other technologies may serve to recall Personal Information previously indicated by a user.",
        "Most browsers allow you to control cookies, including whether or not to accept them and how to remove them.",
        "You may set most browsers to notify you if you receive a cookie, or you may choose to block cookies with your browser.",
        "Tracking technologies may record information such as Internet domain and host names; Internet protocol (IP) addresses;",
        "browser software and operating system types; clickstream patterns; and dates and times that our site is accessed.",
        "Our use of cookies and other tracking technologies allows us to improve our Platform and the overall experience.",
        "We may also analyse information that does not contain Personal Information for trends and statistics.",
      ],
    },
    {
      title: "PERSONS WE SHARE YOUR DATA WITH",
      content: [
        "We may disclose your personal data to any of our employees, officers, and agents as long as such disclosure is reasonably necessary for the purposes, and on the legal bases, set out in this Privacy Policy.",
        "We may disclose your personal data to any member of our group of companies (this means our ultimate holding company and all its subsidiaries and joint venture partners) as long as such disclosure is reasonably necessary for the purposes, and on the legal bases, set out in this Policy.",
        "We may disclose your personal data to our insurers and professional advisers as long as such disclosure is reasonably necessary for the purposes of obtaining or maintaining insurance coverage, managing risks, obtaining professional advice, or the establishment, exercise or defence of legal claims, whether in court proceedings or in an administrative or out-of-court procedure.",
        "We may disclose your personal data to our suppliers or subcontractors or clients as long as such disclosure is reasonably necessary for providing our services.",
        "Financial transactions relating to the Platform and services may be handled by our  payment services providers .",
        "We will share transaction data with our payment services providers only to the extent necessary for the purposes of processing your payments, refunding such payments and dealing with complaints and queries relating to such payments and refunds.",
        "We may disclose your personal data to third-party suppliers of goods and services for the purpose of enabling them to contact you so that they can offer, market and sell to you the relevant goods and/or services.",
        "In addition to the specific disclosures of personal data set out in this Section, we may disclose your personal data where such disclosure is necessary for compliance with a legal obligation to which we are subject, or in order to protect your vital interests or the vital interests of another natural person.",
        "We may also disclose your personal data where such disclosure is necessary for the establishment, exercise or defence of legal claims, whether in court proceedings or in an administrative or out-of-court procedure.",
      ],
    },
    {
      title: "HOW WE WORK WITH THIRD-PARTIES",
      content: [
        "We pass information to third-parties.",
        "In some instances, we disclose Personal Information to third-parties when it is necessary to deliver a service or product, or to help us improve your experience with us or our clients, or when we are required to do so by contract or law.",
        "“Third-parties” include agents, subcontractors, sponsors and other associated organisations.",
        "We have in place contracts to ensure the information remains secure and limited in use, and if we do not have a contract in place and/or have a legitimate business reason to pass on your information, we will ask you to give consent first.",
        "Some examples of when we share your information are below:",
        "When you make a payment on the Platform, your payment will be processed by a specialist payment processor to ensure a secure transaction.",
        "All payment processors used by us are compliant with required security standards.",
        "When you log on to a third-party system using the Platform, the concerned third-party provider will manage your access to its system.",
        "When we test and launch new products, services or offers, we may work with trusted third-parties to support us.",
        "Third-parties who pass information to us.",
        "Our services sometimes use additional information such as telephone numbers or postcodes from third-parties (like list researchers or registrar agents, who have gathered this information lawfully) to help us to contact you with important service updates or to help us make marketing decisions.",
        "These third-parties may give us access to your personal information, if you have allowed them to do so.",
        "We may also work with third-parties to identify individuals who may be interested in our products and services or to perform our service obligations.",
        "These third-parties may give us access to your personal information, if you have allowed them to do so.",
        "The collection, use, and disclosure of information by these third-parties are described in their own privacy policies, and consequently may differ from that set out in this Policy.",
        "We are not responsible for those third-party privacy policies, and you should ensure that you have read and understood all applicable privacy policies before proceeding.",
      ],
    },
    {
      title: "RETAINING AND STORING YOUR INFORMATION",
      content: [
        "Data Retention policy.",
        "We securely store your information, and hold it for as long as we need to in order to provide our products and services to you in accordance with (i) applicable law, or (ii) as long as is set out in any relevant contract you have with us.",
        "We review our retention periods for personal information regularly.",
        "If you have not interacted with us in any way, we will generally no longer hold your information after 7 years from its last use.",
        "We would only keep it for longer than this if we are required to by law or if we have a legitimate reason to do so.",
        "Sometimes we may need to keep it for longer periods for reasons such as tax and other financial regulatory mandates.",
        "Data Erasure Policy.",
        "If you request for us to no longer contact you, for example with marketing communications, we will retain the minimum amount of information about you so that we can ensure we remove you from any future communications.",
        "Please note that if you ask us to completely remove all information about you, and you subsequently use our products and services at a later date, we will no longer be able to recognise your previous request not to be contacted, which is why we would keep it and suppress it in line with industry standards.",
        "Storing and transferring information internationally.",
        "As the internet is a global environment and we work with third-parties across the globe, collecting and using your personal information may involve the transfer of this information internationally.",
        "By using our products and services, you acknowledge and agree to your personal information being transferred in this way, including to jurisdictions outside your country.",
        "We will maintain strict policies to ensure all information that is transferred is done so safely and securely.",
        "Keeping your information safe.",
        "We take information security seriously and have policies and procedures in place to ensure the information we hold on you remains safe.",
        "We limit who has access to your information and ensure that those who do are bound by contracts to keep your information confidential and safe.",
        "While we endeavour to take all reasonable and appropriate steps to keep secure the information collected and prevent unauthorized access, you acknowledge that the internet is not entirely secure and that we cannot and therefore do not provide any absolute assurance regarding the security of the information collected.",
        "We also cannot warrant that your information may not be misused in the event our safeguards and protocols are breached by a malicious third-party.",
        "Further, we are not liable to, nor can we fully control the actions of other users with whom you may choose to share your information.",
        "If any security breach comes to our knowledge, then we will endeavour to take all reasonable steps required to protect misuse of such information.",
        "Individuals under 16.",
        "We do not intentionally process personal information of individuals under the age of 16. If we become aware that we have collected such data, we will take steps to delete it.",
        "We will make every effort to delete any details of such users where a parent or guardian has informed us that these details have been collected.",
      ],
    },
    {
      title: "YOUR RIGHTS REGARDING YOUR INFORMATION WITH US",
      content: [
        "You may exercise your rights which are available to data subjects in relation to your Personal Information which is being held or processed by us.",
        "Rights under the GDPR.",
        "Under certain circumstances, you have the right to:",
        "Request access  to your personal data (commonly known as a “ data subject access request ”).",
        "This enables you to receive a copy of the personal data we hold about you.",
        "Request correction  of the personal data that we hold about you.",
        "This enables you to ask us to correct any incomplete or inaccurate information we hold about you.",
        "Request erasure  of your personal data.",
        "This enables you, in certain situations, to request the deletion or removal of personal data if there is no valid reason for us to continue processing it.",
        "You also have the right to ask us to delete or remove your personal data where you have exercised your right to object to processing (see below).",
        "Object to processing  of your personal data where we are relying on a legitimate interest (or those of a third-party) and there is something about your particular situation which makes you want to object to processing on this ground.",
        "You also have the right to object where we are processing your personal data for direct marketing purposes.",
        "Request the restriction of processing  of your personal data.",
        "This enables you to ask us, in certain circumstances, to suspend the processing of personal data about you, for example if you want us to establish its accuracy or the reason for processing it.",
        "Data portability:",
        "This enables you to ask us, in certain circumstances, to provide you with the personal data you have provided to us in a structured, commonly used and machine-readable format or to transmit the personal data that you have provided to us to another party.",
        "Automated decision-making :",
        "The right not to be subject to a decision based solely on automated processing that produces legal effects concerning you or similarly significantly affects you.",
        "Right to withdraw consent:",
        "In the limited circumstances where you may have provided your consent to the collection, processing and transfer of your personal data for a specific purpose, you have the right to withdraw your consent for that specific processing at any time.",
        "To withdraw your consent, please contact our Data Protection Officers (details provided in this Policy).",
        "Once we have received a notification that you have withdrawn your consent, we will no longer process your information for the purpose or purposes you originally agreed to, unless we have another legal basis for doing so.",
        "Rights under the CCPA.",
        "If the processing of your personal information is subject to the CCPA, you are entitled to the rights listed below.",
        "Request access  to your personal data.",
        "This enables you to receive a copy of the personal data we hold about you.",
        "Request erasure  of your personal data.",
        "This enables you to ask us, in certain situations, to delete or remove personal data unless our processing is specifically permitted under the CCPA.",
        "Right of disclosure for sale of data or use for business purpose.",
        "This enables you to ask us, in certain circumstances, to provide you with the categories personal data and third-parties we disclose for sale or business purposes.",
        "Data portability:",
        "This enables you to ask us, in certain circumstances, to provide you with the personal data you have provided to us in a structured, commonly used and machine-readable format or to transmit the personal data that you have provided to us to another party.",
        "Right to opt-out:",
        "This enables you to opt-out of the sale of your personal data by a business to other third-parties.",
        "Right to non-discrimination :",
        "This specifies that we will not discriminate against you in any way or under any circumstance for exercising your rights to your data under the CCPA.",
        "Right to withdraw consent :",
        "In the limited circumstances where you may have provided your consent to the collection, processing and transfer of your personal data for a specific purpose, you have the right to withdraw your consent for that specific processing at any time.",
        "To withdraw your consent, please contact our Data Protection Officers (details provided in this Policy).",
        "Once we have received a notification that you have withdrawn your consent, we will no longer process your information for the purpose or purposes you originally agreed to, unless we have another legal basis for doing so.",
        "Rights in general.",
        "We provide you the right to keep your personal information held by us accurate and up-to-date.",
        "If at any time you would like to:",
        "Rectify, update or correct your Personal Information",
        "Obtain confirmation on whether or not your Personal Information is processed by us",
        "Access your Personal Information",
        "you may do so by contacting our Data Protection Officers (details provided in this Policy).",
        "Do note that if you exercise any of the above rights, we may need to request specific information from you to help us confirm your identity and to ascertain that you are entitled to make such a request.",
        "This is to ensure that your personal data is not disclosed to any person who is not authorised to receive it.",
        "To exercise certain rights, you may need to contact the designated Data Protection Officer.",
        "You may be charged a fee for the processing of your request, particularly in case of frivolous, excessive, or manifestly unfounded requests.",
        "Do note that your rights listed in this Privacy Policy are not absolute and are subject to limitations as per the applicable data protection laws.",
      ],
    },
    {
      title: "INTERNATIONAL TRANSFERS",
      content: [
        "Some countries may not provide the same level of protection for personal data as others, and data protection laws often restrict the transfer of personal data across borders unless specific conditions are met.",
        "As we and many of our third-party service providers are located in different countries, including USA and India, the processing of your personal data may involve international transfers.",
        "When such transfers occur, we take steps to ensure a comparable level of data security by implementing one or more of the following safeguards:",
        "We will only transfer your personal data to companies that have appropriate measures to data security;",
        "or",
        "We will only transfer your personal data to companies that comply with the data privacy laws.",
        "If none of the above safeguards is available, we may request your explicit consent to the specific transfer.",
        "You will have the right to withdraw this consent at any time.",
      ],
    },
    {
      title: "CALIFORNIA CONSUMER PRIVACY ACT (CCPA)",
      content: [
        "In addition to the rights documented elsewhere in this Privacy Policy, if you are a California Resident you have some additional rights under the CCPA.",
        "These include being informed of any of your personal data that has been “sold” (where sold for this purpose is defined below) and to opt out of the future selling of your personal data to third parties.",
        "Please note that if you opt in, we may share your details with selected third parties, which may be considered a 'sale' of data under the CCPA.",
        "You can opt out of the sale of personal data at any time by contacting us.",
        "To submit requests for information about any of your personal data which we have sold according to the CCPA, you can get in touch with us by any of the methods listed in the Contact Us section of this policy.",
        "If you are emailing us for a request, please indicate in the subject that it is a CCPA-related request.",
      ],
    },
    {
      title: "LIMITATION OF LIABILITY",
      content: [
        "We are not responsible for verifying the authenticity of the information supplied by you.",
        "In case of any erroneous data, our liability is limited to removal of such data from our system.",
        "We are not liable for any data loss or theft due to an unauthorized access to your computer or device.",
        "We shall not be responsible for any breach of security or for any actions of any third parties that receives your personal data or events that are beyond our reasonable control including, acts of government, computer hacking, unauthorised access to computer data and storage device, computer crashes, breach of security and encryption, poor quality of internet service or telephone service etc.",
        "Our liability is strictly limited in all cases to the extent set out in the Terms of Use document;",
        "please read the Terms of Use document before sharing your data with us.",
      ],
    },
    {
      title: "DERIVATIVE DATA",
      content: [
        "We may generate derived data and anonymous statistical information based on the data available with us.",
        "Such derived data and anonymous statistical information shall not identify you at a personal level, and shall be exclusively our intellectual property.",
        "We may use such data or information for any legitimate purpose as we may determine, without any compensation to you.",
      ],
    },
    {
      title: "CHANGES TO THIS PRIVACY POLICY",
      content: [
        "We may update this Privacy Policy from time to time.",
        "We will notify you of any changes by posting the new Privacy Policy on this page.",
        "You are advised to review this Privacy Policy periodically for any changes.",
        "Changes to this Privacy Policy are effective when they are posted on this page.",
      ],
    },
    {
      title: "CONTACT US / DATA PROTECTION OFFICER / GRIEVANCE OFFICER",
      content: [
        "If you have questions about this Policy or about your Personal Information, or if you wish to exercise a right regarding your information, please contact the Data Protection Officer as under.",
        "Email: keshav@augrade.com,",
        "Mailing address:  To the Data Protection Officer / The Grievance Officer,  ",
        " \t\tThe Legal Counsel, Augrade Private Limited",
        " \t\t#702, Floor 7, Wing B, Krishna CHSL",
        " \t\tNeelkanth Vihar Complex, Chembur",
        " \t\tMumbai, Maharashtra, India - 400089",
      ],
    },
  ];
  return (
    <Container maxWidth="lg">
      <StyledPaper elevation={3}>
        <Box mb={4}>
          <Typography variant="h4" component="h1" gutterBottom>
            Privacy Policy
          </Typography>
          <Typography variant="subtitle1" color="textSecondary" gutterBottom>
            Version 1.1 - Effective from 01 August 2023
          </Typography>
        </Box>

        <Box mb={4}>
          <Typography variant="h6" component="h1" gutterBottom>
            Introduction
          </Typography>
          <Typography variant="body1" paragraph>
            {`We, Augrade Private Limited and our affiliated companies worldwide respect your privacy. We are committed to implement the necessary systems and process which (a) ensure an appropriate level of protection of personally identifiable information that a visitor or a user or our business partner share with us through our website, applications, mobile applications and online services or whose information we otherwise receive in connection with our products and services. This Privacy Policy will be updated from time-to-time. Please refer back regularly to keep yourself updated.`}
          </Typography>
        </Box>

        <Box mb={4}>
          {sections.map((section, index) => (
            <Accordion
              key={index}
              expanded={expanded === `panel${index}`}
              onChange={handleChange(`panel${index}`)}
              TransitionProps={{ unmountOnExit: true }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}bh-content`}
                id={`panel${index}bh-header`}
              >
                <Typography variant="title1">{section.title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  {section.content.map((item, itemIndex) => (
                    <StyledListItem key={itemIndex}>
                      <ListItemText
                        primary={item}
                        primaryTypographyProps={{
                          style: {
                            whiteSpace: "pre-wrap",
                            marginLeft: item.startsWith("•")
                              ? theme.spacing(2)
                              : 0,
                          },
                        }}
                      />
                    </StyledListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>

        <Box mt={4}>
          <Typography variant="body2" color="textSecondary" align="center">
            © Augrade Inc . All rights reserved.
          </Typography>
        </Box>
      </StyledPaper>
    </Container>
  );
};

export default PrivacyPolicy;
