import React, { useState } from "react";
import {
  Container,
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
  Paper,
  useTheme,
  styled,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const DpaTerms = () => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const sections = [
    {
      title: "1. Definitions",
      content: [
        "'Applicable Data Protection Laws' means all state and national data protection, privacy and data security laws applicable to the processing of personal data, including but not limited to, GDPR;",
        "the United Kingdom Data Protection Act 2018 (‘UK GDPR’); the Swiss Federal Act on Data Protection (‘FADP’);",
        "the California Consumer Privacy Act of 2018, Cal. Civ. Code § 1798.100-.199 (‘CCPA’);",
        "the Indian Digital Personal Data Protection Act, 2023.",
        "'Client Data' means the information, including but not limited to Personal Data, from a Client's Augrade account that the Client consents to share with Augrade.",
        "'Controller' means the entity which determines the purposes and means of the Processing of Personal Data.",
        "'Processor' means the entity which processes Personal Data on behalf of the Controller.",
        "'Security Incident' means the accidental or unlawful destruction, loss, alteration, unauthorized disclosure of, or access to, Client Data.",
        "“Standard Contractual Clauses” or “SCC” means the Appendix to the European Commission Implementing Decision ((EU) 2021/914 of 4 June 2021) on Standard Contractual Clauses for the transfer of personal data to third countries pursuant to Regulation (EU) 2016/679 of the European Parliament and of the Council.",
        "“UK Addendum” means the ‘Mandatory Clauses of the Approved Addendum, being the template Addendum B.1.0 issued by the ICO and laid before Parliament in accordance with s119A of the Data Protection Act 2018 on 2 February 2022, as it is revised under Section ‎‎18 of those Mandatory Clauses’.",
        "“UK Addendum” means the ‘Mandatory Clauses of the Approved Addendum, being the template Addendum B.1.0 issued by the ICO and laid before Parliament in accordance with s119A of the Data Protection Act 2018 on 2 February 2022, as it is revised under Section ‎‎18 of those Mandatory Clauses’.",
        "“User Data” means the information, including but not limited to Personal Data, from a User's Augrade account that the User consents to share with Client.",
      ],
    },
    {
      title: "2. Data Processing",
      content: [
        "Scope and Roles. This DPA applies when Personal Data is transferred between Augrade and Client.",
        "The Parties agree that the status of each Party as a Controller or Processor is a factual determination under Applicable Data Protection Laws.",
        "Compliance with Applicable Data Protections Laws. The Parties represent that (a) the User Data shall be lawfully collected and transferred in accordance with Applicable Data Protection Laws;",
        "and (b) the Parties have, and shall maintain, the systems and processes in place to ensure compliance with the terms of the Agreement.",
        "Cooperation between the Parties. The Parties will assist each other to comply with requests or complaints of data subjects or supervisory authorities regarding compliance with Applicable Data Protection Laws with regard to User Data.",
        "The Parties will notify each other of any requests, enquiries, monitoring activities and similar measures undertaken by supervisory authorities regarding the handling of Personal Data under this DPA.",
        "California Consumer Privacy Act (“CCPA”). To the extent that the CCPA is applicable, the parties acknowledge and agree that where Augrade is a processor, Augrade shall also be considered a service provider for the purposes of the CCPA.",
        "Augrade certifies that it understands the rules, restrictions, requirements, and definitions of the CCPA and agrees to refrain from taking any action that would cause any transfers of Personal Data to or from Augrade to qualify as a sale of Personal Data under the CCPA.",
        "Augrade acknowledges and confirms that it does not receive any Personal Data from Client as consideration for any services or other items provided to Client.",
        "Augrade shall not sell any Client Data.",
        "Augrade shall not retain, use or disclose any Client Data except as necessary for the specific purpose of performing the services for Client pursuant to the Agreement or otherwise as set forth in the Agreement or as permitted by the CCPA.",
        "For purposes of this Section 1.3, the terms “Personal Data,” “service provider,” “sale,” and “sell” are as defined in Section 1798.140 of the CCPA.",
        "Details of Data Processing.",
        "Subject matter: The subject matter of the data processing under this DPA is personally identifiable Client Data or User Data.",
        "Duration: As between Augrade and Client, the duration of the data processing under this DPA is for the Term of the Agreement.",
        "Purpose: The purpose of the data processing under this DPA is the provision of the Services.",
        "Nature of the processing: Augrade will provide a platform for Client to use the Services.",
        "Categories of Personal Data: Client Data or User Data.",
        "Categories of data subjects: The data subjects may include Users or Client’s customers, employees, and end-users.",
        "Location:  Client agrees that Augrade shall be entitled to transfer and process Client Data within the European Economic Area and the UK.",
        "Client also consents to the transfer and/or processing of Client Data within or outside the European Economic Area and UK (“Approved Data Storage Geographies”) provided that the transfer is in accordance with one of the allowed mechanisms prescribed by the Data Protection Law, unless otherwise specified in an applicable Order Form.",
        "Storage and Pseudonymization. Notwithstanding anything to the contrary in this Agreement, the Parties acknowledge that Augrade stores Personal Data, including Client Data, in the Approved Data Storage Geographies, and the storage by Augrade of Personal Data in the United States shall not be deemed a violation of this Section or create a right of action under this Agreement.",
        "Some Services require processing and analysis of Personal Data by Augrade Affiliates.",
        "Such data will be pseudonymized and encrypted in transit and at rest during any such processing.",
      ],
    },
    {
      title: "3. Client Instructions",
      content: [
        "The Parties agree that this DPA and the Agreement constitute Client's documented instructions regarding Augrade's processing of Client Data (‘Documented Instructions’).",
        "Augrade will process Client Data only in accordance with Documented Instructions.",
        "Client shall obtain all consents required by any Applicable Data Protection Law from Users for Augrade to lawfully store, transfer, and process Personal Data provided by Client to Augrade pursuant to the Agreement.",
        "Additional instructions outside the scope of the Documented Instructions (if any) require prior written agreement between Augrade and Client, including agreement on any additional fees payable by Client to Augrade for carrying out such instructions.",
        "Client is entitled to terminate this DPA and the Agreement if Augrade declines to follow instructions requested by Client that are outside the scope of, or changed from, those given or agreed to be given in this DPA.",
        "Government Request for Client Data",
        "If a governmental body sends Augrade a demand for Client Data, Augrade will attempt to redirect the governmental body to request that data directly from Client.",
        "As part of this effort, Augrade may provide Client’s basic contact information to the government body.",
        "If compelled to disclose Client Data to a government body, then Augrade will give Client reasonable notice of the demand to allow Client to seek a protective order or other appropriate remedy unless Augrade is legally prohibited from doing so.",
      ],
    },
    {
      title: "4. Government Request for Client Data",
      content: [
        "If a governmental body sends Augrade a demand for Client Data, Augrade will attempt to redirect the governmental body to request that data directly from Client.",
        "As part of this effort, Augrade may provide Client’s basic contact information to the government body.",
        "If compelled to disclose Client Data to a government body, then Augrade will give Client reasonable notice of the demand to allow Client to seek a protective order or other appropriate remedy unless Augrade is legally prohibited from doing so.",
      ],
    },
    {
      title: "5. Confidentiality Obligations of Augrade Personnel",
      content: [
        "Augrade restricts its personnel from processing Client Data without authorization by Augrade.",
        "Augrade shall impose appropriate contractual obligations upon its personnel, including relevant obligations regarding confidentiality, data protection and data security.",
      ],
    },
    {
      title: "6. Security of Data Processing",
      content: [
        "Augrade has implemented and will maintain the technical and organizational measures for the Services as described in the Information Security Standards.",
      ],
    },

    {
      title: "7. Sub-processing",
      content: [
        "Authorized Sub-processors. Client agrees that Augrade may use sub-processors or other Augrade group companies to fulfill its contractual obligations under this DPA or to provide certain services on its behalf.",
        "The Augrade website lists sub-processors that are currently engaged by Augrade to carry out processing activities on Client Data on behalf of Client.",
        "Augrade will provide at least 30 days’ prior notice before engaging any new sub-processor to carry out processing activities on Client Data on behalf of Client, Augrade will email notice to the notice email set forth on the Order Form or otherwise provided to Augrade in writing from time to time.",
        "If Client reasonably objects to a new sub-processor and such objection cannot be satisfactorily resolved within a reasonable time, Client may terminate this Agreement without penalty upon 30 days’ written notice to Augrade.",
        "Sub-processor Obligations. Where Augrade authorizes any sub-processor as described in Section 7.1.:",
        "Augrade will restrict the sub-processor’s access to Client Data only to what is necessary to maintain the Services or as necessary under the Agreement;",
        "and Augrade will enter into a written agreement with the sub-processor and, to the extent that the sub-processor is performing the same data processing services that are being provided by Augrade under this DPA, Augrade will impose on the sub-processor the material contractual obligations that Augrade has under this DPA;",
        "and Augrade will remain responsible for its compliance with the obligations of this DPA and for any acts or omissions of the sub-processors that violate the obligations under this DPA as if caused by Augrade itself.",
      ],
    },
    {
      title: "8. Data Subject Requests",
      content: [
        "Should a data subject contact Augrade with regard to correction or deletion of Client Data, Augrade will direct such data subject to Client.",
      ],
    },
    {
      title: "9. Security Breach Notification",
      content: [
        "Security Incident. Augrade will (i) notify Client of a Security Incident without undue delay after becoming aware of the Security Incident, (ii) investigate the Security Incident;",
        "(iii) provide Client with a summary about the Security Incident, and (iv) take reasonable steps to mitigate the effects resulting from the Security Incident and enact procedures to prevent a recurrence of the Security Incident.",
        "Augrade Assistance. To assist Client in relation to any personal data breach notifications Client is required to make under the Applicable Data Protection Laws, Augrade will include in the notification under section 9.1.",
        "such information about the Security Incident as Augrade is reasonably able to disclose to Client, taking into account the nature of the Services, the information available to Augrade, and any restrictions on disclosing the information, such as confidentiality.",
        "Augrade’s obligation to report or respond to a Security Incident under this Section is not and will not be construed as an acknowledgement by Augrade of any fault or liability of Augrade with respect to the Security Incident.",
        "Client Obligations. Where a controller-to-controller relationship exists between Augrade and Client, Client shall notify Augrade without undue delay in the event a personal data breach, as defined in the GDPR, occurs that requires Client to notify the competent supervisory authority or other regulator and/or the impacted data subjects.",
      ],
    },
    {
      title: "10. Augrade Certification and Audit Right",
      content: [
        "Augrade Audits. Augrade uses external auditors to verify the technical, organizational and security measures, including the security of the physical data centers from which Augrade provides the Augrade Services.",
        "This audit: (a) will be performed at least annually; (b) will be performed according to the ISO27001 standard or such other alternative standards that are substantially equivalent to ISO27001;",
        "(c) will be performed by independent third-party security professionals at Augrade’s selection and expense; and (d) will result in the generation of an audit report (the “Report”), which will be Augrade’s Confidential Information.",
        "Audit Reports. At Client’s written request, Augrade will provide Client, on Client’s request, with a copy of the Report so that Client can reasonably verify Augrade’s compliance with its obligations under this DPA.",
        "Privacy Impact Assessment and Prior Consultation. Taking into account the nature of the Services and the information available to Augrade, Augrade will assist Client in complying with Client’s obligations in respect of data protection impact assessments and prior consultation pursuant to GDPR, by providing the information Augrade makes available under this Section.",
        "Client Audit. After Client has exercised its rights under paragraph 10.3.",
        "above, if Client requires further information Client will have the right to, at its own cost and subject to Client’s payment of Augrade’s fees in relation to such audit at its standard professional services rates prevailing at the time, require that Augrade (or its independent third-party auditors) carry out a bespoke audit in relation to Augrade’s compliance with this DPA.",
        "Upon receipt of such request, Augrade will carry out (or arrange) such audit within such reasonable period as Client and Augrade may agree and will provide a copy of the audit report to Client promptly following conclusion of the audit.",
      ],
    },
    {
      title: "11. Limitation of Liability",
      content: [
        "Each party’s liability taken together in the aggregate arising out of or related to this DPA (including the SCCs) shall be subject to the exclusions and limitations of liability set forth in the Agreement.",
        "In no event shall either party limit its liability with respect to any individual’s data protection rights under this DPA or otherwise.",
      ],
    },
    {
      title: "12. Application of Standard Contractual Clauses",
      content: [
        "EU GDPR. The Standard Contractual Clauses will apply to Client Data that is transferred outside the EEA, UK, or Switzerland, either directly or via onward transfer, to any country not recognized by the European Commission, UK Information Commissioner’s Office, or the Swiss FDPIC as providing an adequate level of protection for personal data (as described in the GDPR or Swiss FADP).",
        "The Standard Contractual Clauses will not apply to Client Data that is not transferred, either directly or via onward transfer, outside the EEA.",
        "UK GDPR. Where the transfers contemplated under this Section 12 result in transfers of UK Personal Data to Augrade for processing by Augrade in a jurisdiction other than in the UK or UK Information Commissioner’s Office-approved countries providing ‘adequate’ data protection, then each party agrees that (a) the UK Addendum for transfers of UK Personal Data shall apply;",
        "and (b) the UK Addendum will be deemed executed by and between Client and Augrade;",
        "and (c) the SCCs between the parties shall be deemed amended as specified in the UK Addendum in respect of the transfer of such UK Personal Data.",
        "The UK Information Commissioner is the exclusive Supervisory Authority for the transfers of UK Personal Data under this Agreement.",
        "Swiss FDPIC. Where the transfers contemplated under this Section 12 result in transfers of Swiss Personal Data to Augrade for processing by Augrade in a jurisdiction other than in the EAA, then each party agrees that (a) references in the SCC to a “member state” or to the “EU” shall be deemed to include Switzerland;",
        "and (b) the SCC between the parties shall be deemed amended as the Swiss FDPIC is the exclusive Supervisory Authority for the transfers of Swiss Personal Data under this Agreement.",
        "Future Amendments. In the event that the Standard Contractual Clauses are amended, replaced or repealed by the European Commission or otherwise under Data Protection Laws, the Parties shall work together in good faith to enter into any updated version or negotiate in good faith a solution to enable a transfer of Personal Data to be conducted in compliance with Data Protection Laws.",
        "Either Party may terminate the Agreement on 30 days’ written notice, if the Parties are incapable of implementing or fail to implement another appropriate safeguard to ensure an adequate level of data protection within a period of 90 days.",
      ],
    },
    {
      title: "13. Termination of the DPA",
      content: [
        "This DPA shall continue in force until the termination of the Agreement (the “Termination Date”).",
      ],
    },
    {
      title: "14. Return or Deletion of Client Data",
      content: [
        "Up to the Termination Date, Client will continue to have the ability to retrieve or delete Client Data in accordance with this Section.",
        "Augrade will delete Client Data when requested by Client and promptly following the Termination Date.",
        "Augrade will have no obligation to maintain Customer Data after the Termination Date, and may thereafter delete or destroy all copies of Client Data maintained by Augrade.",
      ],
    },
    {
      title: "15. Duties to Inform",
      content: [
        "Where Client Data becomes subject to confiscation during bankruptcy or insolvency proceedings, or similar measures by third parties while being processed by Augrade, Augrade will inform Client without undue delay.",
        "Augrade will, without undue delay, notify all relevant parties in such action (e.g., creditors, bankruptcy trustee) that any Client Data subjected to those proceedings is Client’s property and area of responsibility and that Client Data is at Client’s sole disposition.",
      ],
    },
    {
      title: "16. Entire Agreement; Conflict",
      content: [
        "Except as amended by this DPA, the Agreement will remain in full force and effect.",
        "If there is a conflict between any other agreement between the parties including the Agreement and this DPA, the terms of this DPA will control.",
        "If there is a conflict between the Standard Contractual Clauses and this DPA, the terms of the Standard Contractual Clauses, as applicable, shall prevail.",
      ],
    },
  ];
  return (
    <Container maxWidth="lg">
      <StyledPaper elevation={3}>
        <Box mb={4}>
          <Typography variant="h4" component="h1" gutterBottom>
            DATA PROCESSING AGREEMENT
          </Typography>
        </Box>

        <Box mb={4}>
          <Typography variant="h6" component="h1" gutterBottom>
            Introduction
          </Typography>
          <Typography variant="body1" paragraph>
            {`This Data Processing Agreement (“DPA”) is between Augrade Inc., and Augrade Private Limited (collectively, “Augrade”) and the subscriber of the Augrade Dreamspace Solution (“Client”), and is incorporated into the Terms of Subscription or other agreement between Client and Augrade governing use of the Services (the “Agreement”) when Personal Data is transferred between Augrade and Client. Unless otherwise defined in this DPA or in the Agreement, all capitalized terms used in this DPA will have the meanings given to them in the Agreement. In the event of a conflict between terms, this DPA shall prevail.  `}
          </Typography>
        </Box>

        <Box mb={4}>
          {sections.map((section, index) => (
            <Accordion
              key={index}
              expanded={expanded === `panel${index}`}
              onChange={handleChange(`panel${index}`)}
              TransitionProps={{ unmountOnExit: true }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}bh-content`}
                id={`panel${index}bh-header`}
              >
                <Typography variant="h6">{section.title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  {section.content.map((item, itemIndex) => (
                    <StyledListItem key={itemIndex}>
                      <ListItemText
                        primary={item}
                        primaryTypographyProps={{
                          style: {
                            whiteSpace: "pre-wrap",
                            marginLeft: item.startsWith("•")
                              ? theme.spacing(2)
                              : 0,
                          },
                        }}
                      />
                    </StyledListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>

        <Box mt={4}>
          <Typography variant="body2" color="textSecondary" align="center">
            © Augrade Inc . All rights reserved.
          </Typography>
        </Box>
      </StyledPaper>
    </Container>
  );
};

export default DpaTerms;
