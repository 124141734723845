import React, { useState } from "react";
import {
  Container,
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
  Paper,
  useTheme,
  styled,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const TermsOfSubscription = () => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const sections = [
    {
      title: "1. Amendments to these Terms",
      content: [
        "We reserve the right to modify, update, or replace these Terms (including any applicable Supplemental Terms) at our sole discretion. Any such modifications shall take effect upon their publication on this webpage: www.augrade.com/msa",
        "Your continued use of the Platform following the publication of any revised Terms shall constitute your acceptance of such revisions. It is your responsibility to periodically review these Terms to stay informed of any updates, as they shall be binding upon you.",
      ],
    },
    {
      title: "2. Other Legal Documents",
      content: [
        "The following legal documents form an integral part of these Terms and must be read as if fully incorporated herein. By continuing to use the Platform, you acknowledge and agree to the terms set forth in these legal documents:",
        "• Privacy Policy",
        "• Cookie Policy",
        "• Terms of Subscription",
        "Additional terms and conditions may apply to specific services, activities, promotions, or new features introduced on the Platform (“Supplemental Terms”). Such Supplemental Terms shall be disclosed in connection with the relevant services and shall be deemed an integral part of these Terms.",
        "In the event of a conflict between these Terms and any other legal documents or Supplemental Terms, the provisions most favorable to us shall prevail.",
      ],
    },
    {
      title: "3. Account Information",
      content: [
        "You may access and browse the Platform without creating an account. However, certain features and functionalities of the Platform may be accessible only upon the creation of an account.",
        "When creating an account, you shall be required to provide certain information about yourself. You acknowledge that maintaining an active account is contingent upon keeping your information accurate, complete, and up to date at all times. You undertake to promptly update any such information as necessary.",
        "You are solely responsible for maintaining the confidentiality and security of your account credentials. Any actions, transactions, or activities carried out through your account—whether authorized by you or not—shall be your responsibility. We shall bear no liability for any misuse, unauthorised access, or security breaches affecting your account. You expressly agree to indemnify and hold us harmless against any claims, liabilities, or damages arising from the unauthorised use of your account.",
        "You shall not share your account credentials with any third party, except an authorised account administrator. If you are acting on behalf of a third-party principal, you may share your account credentials only with such principal, provided that such use complies with these Terms. You shall not access or use another person’s account unless expressly permitted under these Terms.",
        "In the event of any unauthorised access to or use of your account, you shall immediately notify us at keshav@augrade.com. Failure to do so may result in your continued liability for any transactions or actions taken using your account.",
        "By accessing and using the Platform, you represent and warrant that:",
        "• The information provided by you is true, accurate, and complete;",
        "• If you represent an organisation, you have the legal capacity to enter into binding contracts and assume obligations on behalf of your organisation;",
        "• You are not disqualified by contract, law, or custom from accepting these Terms; and",
        "• Your decision to access, use, or engage in transactions on the Platform is based on your independent judgment, diligence, and assessment.",
      ],
    },
    {
      title: "4. Responsible Use",
      content: [
        "You shall use the Platform and the products and services offered therein in a lawful and responsible manner. Any misuse of the Platform or our products and services, whether directly or indirectly, is strictly prohibited.",
        "Without prejudice to the generality of the foregoing, misuse of the Platform shall include, but not be limited to, engaging in any of the following acts:",
        "• Copying, modifying, hosting, streaming, sublicensing, or reselling the Platform;",
        "• Enabling or permitting any third party to use the Platform or our products or services through your account credentials, whether or not for commercial gain;",
        "• Accessing the Platform or our products or services through any means other than the interface expressly provided or authorised by us;",
        "• Circumventing or attempting to circumvent any access or usage restrictions implemented on the Platform or our products or services;",
        "• Infringing, misappropriating, or otherwise violating our intellectual property rights or the rights of any third party;",
        "• Modifying, adapting, translating, reverse engineering, decompiling, or disassembling any portion of the Platform;",
        "• Impersonating any person or entity, or falsely representing an affiliation with any person or entity;",
        "• Interfering with, disabling, impairing, or otherwise causing harm to the Platform, our products or services, or any third-party assets, including computer terminals, servers, or networks;",
        "• Disrupting or circumventing the security features of the Platform;",
        "• Uploading, transmitting, storing, or making available any content or code that contains viruses, malware, spyware, or other malicious components that could impair or limit the functionality of the Platform or our products or services;",
        "• Collecting, harvesting, or accessing the information regarding any individual without their consent;",
        "• Interfering with, disrupting, or inhibiting any other person’s use or enjoyment of the Platform or our products or services;",
        "• Using the Platform to engage in unsolicited communications, including but not limited to chain letters, junk mail, pyramid schemes, phishing, spamming, or similar activities;",
        "• Using data mining, scraping, or any other data gathering or extraction techniques in connection with the Platform or our products and services;",
        "• Violating any applicable laws, including but not limited to data protection laws such as the General Data Protection Regulation (GDPR) and the California Consumer Privacy Act (CCPA);",
        "• Using the Platform to publish any Prohibited Content (defined below);",
        "• Attempting to do any of the foregoing activities;",
        "• Breaching these Terms, regardless of whether such breach is subsequently rectified.",
        "Prohibited Content shall include, without limitation, any content that:",
        "• Violates any applicable law or regulation or infringes third-party rights;",
        "• Is obscene, sexually explicit, defamatory, or otherwise objectionable;",
        "• Depicts violence or constitutes threatening or harassing material;",
        "• Contains viruses, spyware, adware, pirated software, hacking tools, spamming tools, or any other harmful code that could access, impair, or damage any data, devices, systems, or software in an unauthorised manner;",
        "• Is false, misleading, or deceptive;",
        "• References or promotes gambling, alcohol, tobacco, drugs, firearms, ammunition, fireworks, or explosives;",
        "• Endorses or incites violence, hatred, revenge, racism, sexism, victimisation, or discrimination of any kind;",
        "• Has the potential to cause serious moral harm to children and young persons or threatens their well-being;",
        "• Is likely to result in consumer fraud, product liability, breach of contract, or injury to any third party; or",
        "• Promotes any products or services that fall within any of the foregoing categories.",
        "Any attempt to engage in or facilitate any of the foregoing acts shall constitute misuse of the Platform.",
        "We reserve the right to determine, at our sole discretion, whether any act constitutes misuse of the Platform and to take appropriate action, including but not limited to suspending or terminating access to the Platform, without incurring any liability (including liability for lost data, lost effort, or for refund of any subscription fees or other amounts paid by you).",
      ],
    },
    {
      title: "5. Ownership of UI, etc.",
      content: [
        "All text, graphics, images, videos, user interfaces, photographs, trademarks, logos, sounds, colour combinations, artwork, and computer code (collectively, “UI Elements”), including but not limited to the design, structure, selection, coordination, expression, and arrangement thereof, contained on the Platform is owned, controlled, or licensed by us and may be protected under applicable laws, including trade dress, copyright, patent, and trademark laws.",
        "Except as expressly permitted under these Terms, no part of the Platform and no UI Elements may be copied, reproduced, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, framed, distributed, or otherwise used for any purpose without our prior express written consent.",
      ],
    },
    {
      title: "6. API Access and Usage",
      content: [
        "We may provide you with access to our Application Programming Interface (“API”) for integration with third-party applications or internal systems. Subject to these Terms, we grant you a limited, non-exclusive, non-transferable, revocable license to access and use the API solely for the purpose of integrating and interacting with the Platform.",
        "To access the API, you may be required to obtain API credentials (API Key or Token) issued by us. You are responsible for maintaining the confidentiality and security of your API credentials and shall not share them with any unauthorised third party. Any use of the API using your credentials shall be deemed authorised by you, and you shall be fully responsible for any actions taken using your API Key.",
        "You shall not:",
        "• Use the API in a manner that exceeds rate limits or imposes an unreasonable burden on our infrastructure;",
        "• Resell, sublicense, or distribute API access to third parties without our prior written consent;",
        "• Modify, reverse engineer, decompile, or attempt to extract source code from the API;",
        "• Use the API to create a service that competes with or substitutes the Platform or any of its features;",
        "• Transmit malicious code, automated requests, or unauthorised data harvesting tools through the API.",
        "We do not guarantee uninterrupted or error-free availability of the API.",
        "We reserve the right to modify, suspend, or discontinue API access at our sole discretion, with or without notice. We may introduce new API versions, and you must ensure that your systems remain compatible with any updated API.",
        "You must implement appropriate security measures to protect API usage and prevent unauthorised access. You shall ensure compliance with all applicable laws, regulations, and industry standards when using the API. In the event of a security breach or suspected misuse of the API, you must notify us immediately at keshav@augrade.com.",
        "We may suspend or terminate API access if:",
        "• You violate these Terms or engage in unauthorised activities;",
        "• Your API usage poses a security risk to our systems or other users; and/or",
        "• Your subscription is terminated or expires.",
        "Upon termination, you must cease all API calls and delete any stored API credentials.",
        "We disclaim any liability for disruptions, data loss, or damages resulting from API use.",
        "You agree to indemnify and hold us harmless against any claims arising from API misuse or non-compliance with these Terms.",
        "We provide API documentation to assist with integration. Technical support for the API is available at keshav@augrade.com, subject to your support plan.",
      ],
    },
    {
      title: "7. Use of Artificial Intelligence and Generative AI",
      content: [
        "The Platform may incorporate Artificial Intelligence (“AI”) and Generative AI functionalities, including but not limited to data analysis, automated content generation, predictive analytics, recommendations, and other AI-assisted processes. Certain functionalities on the Platform may utilise Generative AI to create, modify, or enhance content, including but not limited to text, images, and automated responses.",
        "AI-driven features are intended to enhance user experience and operational efficiency. However, users acknowledge that AI-generated content may not always be accurate, complete, or free from bias. AI-generated outputs are provided on an “as-is” basis without warranties of accuracy, reliability, or suitability for any specific purpose. You must independently verify AI-generated content before relying on it.",
        "You shall:",
        "• Exercise discretion when using AI-generated content and shall not rely on it as a substitute for professional advice, including but not limited to legal, financial, or technical advice;",
        "• Not use AI-generated outputs to create, distribute, or amplify false, misleading, deceptive, unlawful, or harmful content; and",
        "• Assume full responsibility for verifying and ensuring compliance with applicable laws and ethical standards when using AI-generated materials.",
        "You shall not:",
        "• Manipulate AI-generated content to engage in fraudulent, deceptive, defamatory, or unlawful activities;",
        "• Use automated bots, AI scrapers, or data extraction tools to mine, manipulate, or exploit data from the Platform;",
        "• Circumvent security measures or attempt to interfere with AI-related functionalities of the Platform;",
        "• Generate or distribute content that infringes intellectual property rights, violates privacy laws, or misrepresents factual information;",
        "• Create misleading, deepfake, or synthetic content that could cause harm to individuals or businesses.",
        "AI-generated content on the Platform may be labelled or disclosed as such where appropriate. Users integrating AI-generated content into external communications, marketing, or public materials shall ensure appropriate disclosure where required by law, industry standards, or ethical norms.",
        "AI-driven features may process and analyse user data to enhance functionality, subject to our Privacy Policy and applicable data protection laws. We do not use AI models to:",
        "• Train third-party AI systems without explicit consent;",
        "• Store or retain sensitive user data beyond the permissible retention period;",
        "• Process personally identifiable information (PII) in a manner inconsistent with our Privacy Policy.",
        "AI-generated content is provided without any express or implied warranties, including but not limited to warranties of accuracy, completeness, or reliability. We disclaim all liability for any decisions, actions, or consequences arising from reliance on AI-generated outputs. You assume full responsibility for verifying the suitability and compliance of AI-generated content before use.",
      ],
    },
    {
      title: "8. Intellectual Property",
      content: [
        "We and our licensors retain all rights, titles, and interests in and to the Platform, including its software, models, databases, algorithms, design, architecture, including in those portions of the Platform that were conceived, designed, encoded, or commissioned at your specific request.",
        "You retain full ownership of any content, data, text, images, files, or other materials that you upload, submit, or input into the Platform (“User Input Materials”). By providing User Input Materials, you grant us a limited, non-exclusive, worldwide, royalty-free license to process, store, and use such materials solely for the purpose of delivering the Platform’s services. We do not claim ownership over User Input Materials, and you remain solely responsible for ensuring that such materials do not infringe third-party rights.",
        "You retain full ownership of any content, data, text, images, files, or other materials that are generated by you through the Platform (“User Output Materials”). By generating User Output Materials, you grant us a limited, non-exclusive, worldwide, royalty-free license to process, store, and use such materials for the purpose of delivering the Platform’s services and for marketing and publicity purposes, subject to the applicable confidentiality provisions.",
        "We may ingest and process data for model learning and service improvements strictly on an anonymized and aggregated basis to ensure no personally identifiable or sensitive information is used. All rights, including intellectual property rights, in such anonymized and aggregated data shall remain our sole and exclusive property.",
        "We shall not use User Input Materials and User Output Materials for model learning unless anonymized, and you acknowledge that anonymized data cannot be requested for deletion or retrieval once integrated into our systems.",
        "You may not:",
        "• Claim ownership over AI-generated outputs beyond the usage rights granted under these Terms;",
        "• Use the User Output Materials in a manner that violates intellectual property laws, including unauthorised reproduction, resale, or sublicensing;",
        "• Attempt to decompile, modify, or reverse engineer the Platform’s AI models, software, or proprietary technology.",
        "We reserve the right to take appropriate legal action against any unauthorised use of the User Output Materials.",
        "You acknowledge that any breach of this section may result in immediate termination of access to the Platform without prior notice.",
        "If you provide feedback, ideas, or suggestions regarding improvements to the Platform (“Feedback”) to us, all intellectual property rights in such Feedback, including the right to use, modify, and incorporate such Feedback into our products and services, shall vest exclusively in us, without any obligation to compensate you or credit you for such Feedback or its resulting benefits.",
      ],
    },
    {
      title: "9. Disclaimer of Warranties",
      content: [
        'The Platform, including all products and services made available therein, is provided on an "as-is" and "as-available" basis. To the maximum extent permitted by applicable law, we disclaim all warranties, whether express, implied, statutory, or otherwise, including but not limited to any implied warranties of merchantability, fitness for a particular purpose, non-infringement, title, or uninterrupted access.',
        "We make no representations or warranties regarding the accuracy, reliability, availability, completeness, or suitability of any content or functionality provided through the Platform or our products and services. Without limiting the generality of the foregoing, we expressly disclaim any warranty that:",
        "• The Platform or our products and services will meet your requirements or expectations, or that they will be continuously available, uninterrupted, secure, timely, or error-free;",
        "• The Platform or our products and services will transmit or receive content or information accurately, reliably, or without delay;",
        "• Any results obtained from the use of the Platform or our products and services will be accurate, effective, or fit for any particular purpose, whether or not such purpose has been disclosed to us;",
        "• The quality of the Platform, or our products and services, will meet your expectations; or",
        "• Any defects, errors, or malfunctions in the Platform or our products and services will be corrected.",
        "To the fullest extent permitted by applicable law, all implied terms, conditions, and warranties, including any implied conditions of satisfactory quality or fitness for a particular purpose, are excluded.",
        "Your use of the Platform and our products and services is entirely at your own discretion and risk. You acknowledge and agree that you assume full responsibility for any loss, damage, or adverse consequence, including but not limited to loss of data, arising from such use.",
        "To the maximum extent permitted by applicable law, we, including our officers, directors, employees, agents, and affiliates, shall not be liable for any claims, liabilities, damages, losses, or expenses (including legal fees and costs) arising out of or in connection with your access to or use of the Platform and our products and services.",
      ],
    },
    {
      title: "10. Limitation of Our Liability",
      content: [
        "To the maximum extent permitted by applicable law, we shall not be liable to you or any third party for any direct, indirect, incidental, special, consequential, or punitive damages, including but not limited to:",
        "• Loss of use, data, efforts, goodwill, profits, or business opportunities;",
        "• Any damages arising from loss of use, data, or profits, whether or not foreseeable;",
        "• Any claims based on breach of contract, breach of warranty, negligence, strict liability, or any other legal theory; or",
        "• Any other claims arising out of or in connection with your use of or access to the Platform and our products and services.",
        "Our aggregate liability to you for any claims arising under or in connection with these Terms shall be limited to whichever is lower:",
        "• USD 100 in the aggregate;",
        "• The amount paid by you for one month's access to the Platform or our products and services immediately preceding the event giving rise to the liability; or",
        "• The minimum amount of liability required to be assumed by us under applicable law.",
        "The foregoing limitation of liability shall apply regardless of the nature or source of the claim, whether the claim or loss was foreseeable, and whether we had been advised of the possibility of such loss or damage.",
      ],
    },
    {
      title: "11. Release and Indemnification",
      content: [
        "You agree to indemnify, defend, and hold harmless us, our affiliates, inventory partners, publishers, and our respective officers, directors, employees, and agents from and against any claims, liabilities, losses, damages, costs, and expenses (including reasonable legal fees) arising out of or in connection with:",
        "• Your violation of these Terms;",
        "• Your use of the Platform or our products and services;",
        "• Any content or materials submitted, uploaded, or transmitted by you via the Platform; or",
        "• Your infringement of any third-party rights, including intellectual property rights or data privacy rights.",
        "We reserve the right, at our sole discretion, to assume the exclusive defense and control of any matter subject to indemnification by you. In such an event, you agree to fully cooperate with us in the defense of such claims. We shall use reasonable efforts to notify you of any such claim, action, or proceeding upon becoming aware of the same.",
        "You shall not settle any claim or matter without obtaining our prior written consent.",
        "You expressly waive any right to participate in any class action or collective proceeding against us arising out of or in connection with your use of the Platform or our products and services.",
        "You hereby release us, our officers, directors, employees, agents, affiliates, and third-party associates from all claims, demands, liabilities, and damages arising out of or in connection with your use of the Platform and our products and services.",
      ],
    },
    {
      title: "12. General Provisions",
      content: [
        "We reserve the right to modify, enhance, or discontinue features of the Platform and our products and services at our sole discretion. Such modifications may result in the introduction of new functionalities or the removal of existing ones, which may lead to the loss of information related to discontinued features. We shall bear no liability for any loss, damage, or inconvenience arising from such modifications.",
        "We may assign or transfer our rights and obligations under these Terms to any third party without prior notice or reference to you. You shall not assign, transfer, or delegate any of your rights or obligations under these Terms without our prior written consent.",
        "If any provision of these Terms is found to be invalid, illegal, or unenforceable under applicable law, such provision shall be interpreted, modified, or replaced to the extent necessary to make it enforceable while preserving the original intent as closely as legally possible. The remaining provisions of these Terms shall remain in full force and effect.",
        "The relationship between you and us is that of independent contractors. Nothing in these Terms shall be construed to create any partnership, joint venture, agency, or employment relationship between the parties. No third party shall have any rights or claims under these Terms.",
        "Confidentiality provisions apply only upon explicit request.",
      ],
    },
    {
      title: "13. Notices",
      content: [
        "Any notice to you shall be deemed duly given if communicated in writing via:",
        "• A reasonably prominent publication on the Platform;",
        "• Email to any email address provided by you or, where applicable, to the email address of your CEO, CFO, or other authorised representative; or",
        "• Physical mail to your last known address or any publicly available address.",
        "Any notice to us shall be deemed duly given if communicated in writing via",
        "• Email to keshav@augrade.com with the subject line “Notice under Terms of Use”; or",
        "• Physical mail addressed to:",
        " \t\tThe Legal Counsel, Augrade Private Limited",
        " \t\t#702, Floor 7, Wing B, Krishna CHSL",
        " \t\tNeelkanth Vihar Complex, Chembur",
        " \t\tMumbai, Maharashtra, India - 400089",
        "• You shall promptly notify us of any changes in your contact information for invoicing purposes. Failure to provide updated information shall not exempt you from your obligation to make timely payments. Changes to invoicing contact information shall be submitted to keshav@augrade.com",
        "• We shall not be liable for any failure or delay in performing our obligations under these Terms due to circumstances beyond our reasonable control, including but not limited 8  to acts of God, terrorism, war, riots, fire, earthquakes, floods, or the failure or degradation of third-party networks or communication infrastructure. No liability shall attach to us on this account.",
      ],
    },
    {
      title: "14. Governing Law and Dispute Resolution",
      content: [
        "These Terms shall be governed by and construed in accordance with the laws of India, excluding any conflict of law principles. The United Nations Convention on Contracts for the International Sale of Goods shall not apply to these Terms.",
        "Any claim, dispute, or 11  objection arising in connection with the Platform that cannot be resolved amicably shall be referred to arbitration. The arbitration shall be conducted in English in accordance with the Indian Arbitration and Conciliation Act, 1996 (as may be amended or re-enacted). The arbitration shall be conducted by a sole arbitrator, and the venue of arbitration shall be Mumbai, India.",
        "Any legal action arising out of or relating to these Terms shall be subject to the exclusive jurisdiction of the courts in Mumbai, India, and you expressly waive any objection to such jurisdiction.",
        "The prevailing party in any dispute shall be entitled to recover all reasonable fees, costs, and expenses incurred in enforcing its rights under these Terms, including reasonable attorneys’ fees.",
        "You acknowledge that these Terms constitute a legally binding contract entered into in India.",
        "Notwithstanding the foregoing, in the event of your unauthorised access to or use of the Platform or our products and services in violation of these Terms, we shall be entitled to seek injunctive relief or other equitable remedies in any jurisdiction as necessary to protect our legal rights and interests.",
      ],
    },
  ];
  return (
    <Container maxWidth="lg">
      <StyledPaper elevation={3}>
        <Box mb={4}>
          <Typography variant="h4" component="h1" gutterBottom>
            Terms of Use
          </Typography>
          <Typography variant="subtitle1" color="textSecondary" gutterBottom>
            Version 1.1 - Effective from 01 August 2023
          </Typography>
        </Box>

        <Box mb={4}>
          <Typography variant="body1" paragraph>
            {`Augrade Inc, together with its worldwide affiliates (collectively,
              "we", "our", or "us"), governs the access to and use of this
              website, and the applications, mobile applications, and online
              services, including any related scripts, source code, instruction
              sets, and documentation made available or associated thereby
              (collectively, the "Platform"). These Terms of Use ("Terms") set
              forth the terms and conditions applicable to your access to and use
              of the Platform.`}
            <br></br>
            <br></br>
            {`
              \n\n
              By accessing or using the Platform, including
              availing any products or services offered therein, you (“you”) (a)
              acknowledge and agree to be bound by these Terms, which constitute a
              legally binding agreement between you and us, and (b) represent and
              warrant that you have the legal capacity to enter into binding
              contracts and assume the liabilities arising therefrom. If you do
              not possess such legal capacity or if you do not agree to these
              Terms, you must immediately discontinue your use of and cease all
              access to the Platform.`}
          </Typography>
        </Box>

        <Box mb={4}>
          {sections.map((section, index) => (
            <Accordion
              key={index}
              expanded={expanded === `panel${index}`}
              onChange={handleChange(`panel${index}`)}
              TransitionProps={{ unmountOnExit: true }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}bh-content`}
                id={`panel${index}bh-header`}
              >
                <Typography variant="h6">{section.title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  {section.content.map((item, itemIndex) => (
                    <StyledListItem key={itemIndex}>
                      <ListItemText
                        primary={item}
                        primaryTypographyProps={{
                          style: {
                            whiteSpace: "pre-wrap",
                            marginLeft: item.startsWith("•")
                              ? theme.spacing(2)
                              : 0,
                          },
                        }}
                      />
                    </StyledListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>

        <Box mt={4}>
          <Typography variant="body2" color="textSecondary" align="center">
            © Augrade Inc . All rights reserved.
          </Typography>
        </Box>
      </StyledPaper>
    </Container>
  );
};

export default TermsOfSubscription;
