import React, { useState } from "react";
import {
  Container,
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
  Paper,
  useTheme,
  styled,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const InformationSecurityPolicy = () => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const sections = [
    {
      title: "Information Security Policies and Standards",
      content: [
        "Augrade will maintain information security policies, standards and procedures.",
        "These policies, standards, and procedures shall be kept up to date and revised whenever relevant changes occur in the information systems that use or store Client Data.",
        "These policies, standards, and procedures shall be designed and implemented to:",
        "Prevent unauthorized persons from gaining physical access to Client Data (e.g. physical access controls);",
        "Prevent Client Data from being used without authorization (e.g. logical access control);",
        "Ensure that Data Personnel gain access only to such Client Data as they are entitled to access (e.g. in accordance with their access rights) and that, in the course of Processing or use and after storage, Client Data cannot be read, copied, modified or deleted without authorization (e.g. data access controls);",
        "Ensure that Client Data cannot be read, copied, modified or deleted without authorization during electronic transmission, transport or storage, and that the recipients of any transfer of Client Data by means of data transmission facilities can be established and verified (e.g. data transfer controls);",
        "Ensure the establishment of an audit trail to document whether and by whom Client Data has been entered into, modified in, or removed from Client Data Processing (e.g. entry controls);",
        "Ensure that Client Data is Processed solely in accordance with Client’s Instructions (e.g. control of instructions);",
        "Ensure that Client Data is protected against accidental destruction or loss (e.g. availability controls);",
        "Ensure that Client Data collected for different purposes can be Processed separately (e.g. separation controls);",
        "Ensure that Client Data maintained or processed for different customers is Processed in logically separate locations (e.g. data segregation);",
        "Ensure that all systems that Process Client Data are subject to a secure software developmental lifecycle;",
        "and",
        "Ensure that all systems that Process Client Data are part of a vulnerability management program that includes, without limitation, internal and external vulnerability scanning, risk rating of findings, and formal remediation plans to address any identified vulnerabilities.",
      ],
    },
    {
      title: "Physical Security",
      content: [
        "Physical Access Controls. The Augrade Services are hosted in a datacenter owned and operated by a third-party hosting provider.",
        "Physical barrier controls are used to prevent unauthorized entrance to the datacenter.",
        "Limited Employee and Contractor Access.",
        "Augrade’s hosting provider provides access to the datacenter to those employees and contractors who have a legitimate business need for such access privileges.",
        "When an employee or contractor no longer has a business need for the access privileges assigned to them, the access privileges are promptly revoked, even if the employee or contractor continues to be an employee of Augrade’s hosting provider or its Affiliates.",
      ],
    },
    {
      title: "Organizational Security",
      content: [
        "Augrade will maintain information security policies and procedures addressing:",
        "Data Disposal.",
        "Procedures for when data are to be disposed or reused have been implemented to prevent any subsequent retrieval of any Client Data before they are withdrawn from the Augrade’s inventory or control.",
        "Data Classification. Policies and procedures to classify sensitive information assets, clarify security responsibilities, and promote awareness for all employees have been implemented and are maintained.",
        "Incident Response. All Client Data security incidents are managed in accordance with appropriate incident response procedures.",
        "Encryption.",
        "All Client Data is stored and transmitted using industry standard encryption mechanisms.",
      ],
    },
    {
      title: "Access Control (Governance)",
      content: [
        "Augrade governs access to information systems that Process Client Data.",
        "Only authorized Augrade staff can grant, modify or revoke access to an information system that Processes Client Data.",
        "Augrade implements commercially reasonable physical and technical safeguards to create and protect passwords.",
      ],
    },
    {
      title: "Virus and Malware Controls",
      content: [
        "Augrade maintains anti-virus and malware protection software on any system that handles Client Data and takes reasonable measures to protect Client Data from malicious code.",
      ],
    },
    {
      title: "Personnel",
      content: [
        "Augrade has implemented and maintains a security awareness program to train all employees about their security obligations.",
        "This program includes training about data classification obligations, physical security controls, security practices, and security incident reporting.",
        "Augrade has clearly defined roles and responsibilities for employees. Data Personnel strictly follow established security policies and procedures.",
        "A disciplinary process is applied if Data Personnel fail to adhere to relevant policies and procedures.",
        "Augrade shall take reasonable steps to ensure the reliability of any employee, agent or contractor who may Process Client Data.",
      ],
    },
    {
      title: "Business Continuity",
      content: [
        "Augrade implements disaster recovery and business resumption plans.",
        "Business continuity plans are tested and updated regularly to ensure they remain current and effective.",
      ],
    },
  ];
  return (
    <Container maxWidth="lg">
      <StyledPaper elevation={3}>
        <Box mb={4}>
          <Typography variant="h4" component="h1" gutterBottom>
            Information Security Policy
          </Typography>
        </Box>

        <Box mb={4}>
          <Typography variant="body1" paragraph>
            {`These terms supplement the Data Processing Agreement (“DPA”) between Augrade and Client. Capitalized terms not otherwise defined in this document have the meanings assigned to them in the DPA. Augrade has agreed to employ appropriate technical and organizational measures to protect against unauthorized or unlawful processing of Client Data (“Information Security Program”) and against accidental loss or destruction of, or damage to, Client Data. Augrade’s Information Security Program shall include specific security requirements for its personnel and all subcontractors, Augrade, or agents who have access to Client Data (“Data Personnel”). Augrade’s security requirements shall cover the below areas.`}
          </Typography>
        </Box>

        <Box mb={4}>
          {sections.map((section, index) => (
            <Accordion
              key={index}
              expanded={expanded === `panel${index}`}
              onChange={handleChange(`panel${index}`)}
              TransitionProps={{ unmountOnExit: true }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}bh-content`}
                id={`panel${index}bh-header`}
              >
                <Typography variant="h6">{section.title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  {section.content.map((item, itemIndex) => (
                    <StyledListItem key={itemIndex}>
                      <ListItemText
                        primary={item}
                        primaryTypographyProps={{
                          style: {
                            whiteSpace: "pre-wrap",
                            marginLeft: item.startsWith("•")
                              ? theme.spacing(2)
                              : 0,
                          },
                        }}
                      />
                    </StyledListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>

        <Box mt={4}>
          <Typography variant="body2" color="textSecondary" align="center">
            © Augrade Inc . All rights reserved.
          </Typography>
        </Box>
      </StyledPaper>
    </Container>
  );
};

export default InformationSecurityPolicy;
